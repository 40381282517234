import { usePage } from "@inertiajs/inertia-react";
import React from "react";

    export function getUserRole() {
        const { auth } = usePage().props;
        return auth && auth.user && auth.role != null && auth.role?.[0]?.name;
    }
export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
export async function checkFileExists(filePath){
    try {
        const response = await fetch(filePath, { method: 'HEAD' });
        return response.ok;
    } catch (error) {
        console.error('Error checking file existence:', error);
        return false;
    }
};
